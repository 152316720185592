import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/version'
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [{
  //     path: 'dashboard',
  //     name: 'Dashboard',
  //     component: () => import('@/views/dashboard'),
  //     meta: { title: 'Dashboard', icon: 'dashboard' }
  //   }]
  // },
  {
    path: '/index/parent',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/',
    redirect: '/index'
    // name: 'Clubhouse',
    // // component: Layout,
    // // component: () => import('@/views/dashboard'),
    // meta: { title: 'Clubhouse', icon: '#' }
  },
  {
    path: '/club',
    component: Layout,
    redirect: '/club/list',
    name: 'Club',
    // meta: {
    //   // title: '俱乐部',
    //   icon: 'el-icon-s-help'
    // },
    children: [
      {
        path: 'list',
        name: 'ClubList1',
        component: () => import('@/views/club/ClubList'),
        meta: { title: '俱乐部', icon: 'el-icon-office-building' }
      }
    ]
  },
  {
    path: '/clubhouseUser',
    component: Layout,
    redirect: '/clubhouseUser',
    name: 'ClubhouseUser',
    children: [
      {
        path: 'list',
        name: 'ClubList',
        component: () => import('@/views/clubhouseUser/index'),
        meta: { title: '成员', icon: 'el-icon-user' }
      }
    ]
  },
  // {
  //   path: '/user',
  //   component: Layout,
  //   redirect: '/user/userList',
  //   name: 'User',
  //   meta: { title: '用户管理', icon: 'el-icon-s-help' },
  //   children: [
  //     {
  //       path: 'userList',
  //       name: 'UserList',
  //       component: () => import('@/views/user/UserList'),
  //       meta: { title: '用户列表', icon: 'table' }
  //     },
  //     {
  //       path: 'userList2',
  //       name: 'UserList2',
  //       component: () => import('@/views/user/UserList'),
  //       meta: { title: '用户列表2', icon: 'table' }
  //     }
  //   ]
  // },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/login/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
