<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    this.getWindowClient()
    window.onresize = () => {
      this.getWindowClient()
    }
  },
  methods: {
    getWindowClient() {
      // let windowWidth = document.documentElement.clientWidth //实时屏幕宽度
      const windowHeight = document.documentElement.clientHeight
      this.$store.commit('settings/SET_HEIGHT', windowHeight)
    }
  }
}
</script>

<style>
html {
  overflow: hidden;
}
body {
  margin: 0;
}
</style>
