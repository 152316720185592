<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }}</h1> -->
        <span class="only-logo">
          c
        </span>
      </router-link>
      <!-- <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 class="sidebar-title">1111</h1>
      </router-link> -->
      <div v-else class="show-sidebar">
        <router-link to="/">
          <div class="show-sidebar-title">{{ title }}</div>
        </router-link>
        <div class="show-sidebar-info">
          <img :src="logo" alt="" />
          <div class="info-name">
            {{
              info
                ? info.oauthAccount && info.oauthAccount !== ''
                  ? info.oauthAccount
                  : info.username
                : '用户'
            }}
          </div>
          <!-- <div class="info-type">正式用户</div> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import touPic1 from '@/assets/toupic/tou1.jpg'
import touPic2 from '@/assets/toupic/tou2.jpg'
import touPic3 from '@/assets/toupic/tou3.jpg'
import touPic4 from '@/assets/toupic/tou4.jpg'
import touPic5 from '@/assets/toupic/tou5.jpg'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    info() {
      return this.$store.state.user.userInfo
    }
  },

  data() {
    return {
      title: 'Clubhouse',
      logo:
        'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'
    }
  },
  created() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))

    let id = userInfo ? userInfo.id : 0

    this.picNum(id)
  },
  methods: {
    picNum(id) {
      let num = id % 5
      if (num == 1) {
        this.logo = touPic1
      } else if (num == 2) {
        this.logo = touPic2
      } else if (num == 3) {
        this.logo = touPic3
      } else if (num == 4) {
        this.logo = touPic4
      } else {
        this.logo = touPic5
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
.only-logo {
  font-size: 28px;
  font-weight: 600;
  color: white;
  font-style: italic;
  &:hover {
    color: rgb(228, 194, 194);
  }
}

.sidebar-logo-container {
  position: relative;
  width: 100%;

  // background: #2b2f3a;
  // text-align: center;

  & .sidebar-logo-link {
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;

      vertical-align: middle;
    }
  }
  .show-sidebar {
    width: 100%;
    background: #2651b2;
    .show-sidebar-title {
      width: 100%;
      height: 60px;
      text-align: center;
      line-height: 60px;
      background: #2651b2;
      font-size: 22px;
      font-weight: bold;
      color: #f9f9f9;
    }
    .show-sidebar-info {
      width: calc(100% - 10px);
      padding: 16px 0;
      height: 168px;
      background: white;
      margin: 0 auto;
      text-align: center;
      img {
        width: 74px;
        height: 74px;
        object-fit: cover;
        border-radius: 50%;
      }
      .info-name {
        font-size: 22px;
        font-weight: 600;
        color: #47474a;
        margin: 5px 0;
      }
      .info-type {
        font-size: 14px;

        color: #89898a;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
